import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Modal, Paper, TextField, Tooltip } from '@mui/material';
import dataList from '../../constants/dataList';
import PhoneNumberInput from '../../components/phoneNumberInput';
import ZipCodeInput from '../../components/zipCodeInput';
import config from '../../config';
import ValidateAddressModal from './validate-address-modal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReactRecaptchaConnected from '../../components/reCaptcha';
import Api from '../../redux/lib/api';
import { ORDER } from '../../redux/constants/api.url';
import { EvaluateExpression, RoundNumber } from '../../hooks/functions/EvaluateExpression';
import { FormatCurrency } from '../../hooks/functions/FormatCurrency';

const API_BASE_URL = {
	API_GATEWAY: config.apiGateway,
};

interface PaymentProps {
	odrQuantity: number;
	narcanQuantity: number;
	taxRate: any[];
	taxRateLoaded: boolean;
	products: any[];
	isTaxLoading: boolean;
	paymentInputs: any;
	isCaptchaValid: boolean;
	createOrder: (payload: { callback: (order: any) => void; order: any }) => void;
	getTaxRate: (payload: { callback: () => void; taxPayload: any }) => void;
	validateAddress: (payload: { callback: (isOpen: boolean) => void; taxPayload: any }) => void;
	clearTaxRate: () => void;
	setSnackbarMessage: (payload: { message: string; type: string, snackbarAutohide?: number }) => void;
	getCcToken: (payload: any) => void;
	setPaymentInputs: (payload: any) => void;
	clearPaymentInputs: () => void;
	setIsCaptchaValid: (isValid: boolean) => void;
}

export const PaymentComponent: React.FC<PaymentProps> = props => {
	const classes = BasePageStyles();
	const history = useHistory();
	const NARCAN_PRODUCT_ID = 'NAR062702';
	const NARCAN_PRICE = 44.99; //hardcode bc looking up products from DB is deprecated, and we need it to have a different price here than on NWP.com //parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);

	const ODR_KIT = config.odrKitProductId
	const ODR_KIT_PRICE = parseFloat(props.products.find((product: any) => product.id === ODR_KIT)?.price || 0);

	const emailRegex = new RegExp(
		/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
	);
	const phoneRegex = new RegExp(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/);
	const zipRegex = new RegExp(/^\d{5}$/);

	const initInputs = () => ({
		shippingAddress: '',
		shippingAddress2: '',
		shippingCity: '',
		shippingState: '',
		shippingZip: '',
		billingAddress: '',
		billingAddress2: '',
		billingCity: '',
		billingState: '',
		billingZip: '',
		name: '',
		businessName: '',
		email: '',
		phoneNumber: '',
	});

	const requiredInputs = () => {
		if (checked) {
			return ['shippingAddress', 'shippingCity', 'shippingState', 'shippingZip', 'name', 'email', 'phoneNumber'];
		}
		return [
			'shippingAddress',
			'shippingCity',
			'shippingState',
			'shippingZip',
			'billingAddress',
			'billingCity',
			'billingState',
			'billingZip',
			'name',
			'email',
			'phoneNumber',
		];
	};

	const [inputs, setInputs] = React.useState({ ...initInputs(), ...props.paymentInputs });
	const [checked, setChecked] = React.useState(props.paymentInputs?.checked || false);
	const [formErrors, setFormErrors] = React.useState<any>({});
	const [submitError, setSubmitError] = React.useState<string>('');
	const [openAddressModal, setOpenAddressModal] = React.useState(false);
	const [enableSubmit, setEnableSubmit] = React.useState(false);
	const [termsChecked, setTermsChecked] = React.useState(false);

	const [discountCodeInputted, setDiscountCodeInputted] = React.useState<string>('');
	const [discountCodeError, setDiscountCodeError] = React.useState<string>('');
	
	const [confirmedDiscountCodeMessage, setconfirmedDiscountCodeMessage] = React.useState<string>('');
	const [confirmedDiscountCode, setconfirmedDiscountCode] = React.useState<string>('');

	const [isShippingAddressSameAsBilling, setIsShippingAddressSameAsBilling] = React.useState<boolean>(false);
	// const [employeeEmailInputted, setEmployeeEmailInputted] = React.useState<string>('');
	// const [employeeEmailInputVisible, setEmployeeEmailInputVisible] = React.useState<boolean>(false);
	// const [isEmployeeEmailRequiredForThisCode, setIsEmployeeEmailRequiredForThisCode] = React.useState<boolean>(false);
	const [discountedUnitPriceODRBox, setDiscountedUnitPriceODRBox] = React.useState<number|null>(null);
	const [discountedUnitPriceNarcan, setDiscountedUnitPriceNarcan] = React.useState<number|null>(null);

	React.useEffect(() => {
		props.clearTaxRate();
		const isShipAddressFilled = (inputs.shippingAddress &&
			inputs.shippingCity &&
			inputs.shippingState &&
			zipRegex.test(inputs.shippingZip)) as boolean;
		setEnableSubmit(isShipAddressFilled);
	}, [inputs.shippingAddress, inputs.shippingAddress2, inputs.shippingCity, inputs.shippingState, inputs.shippingZip, confirmedDiscountCode]);

	React.useEffect(() => {
		props.setPaymentInputs({...inputs, checked});
	}, [inputs, checked]);

	React.useEffect(() => {
		//Begin Checkout
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'begin_checkout',
			ecommerce: {
				tax: EvaluateExpression(calculateTotalTax().toString(), 2),
				items: [
					{
						index: 0,
						price: ODR_KIT_PRICE,
						coupon: '',
						item_id: ODR_KIT,
						discount: 0,
						quantity: props.odrQuantity,
						item_name: "ODRescue Box",
						item_brand: "ODRescue",
						affiliation: "FFF Enterprises",
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
					{
						index: 0,
						price: NARCAN_PRICE,
						coupon: "",
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: props.narcanQuantity,
						item_name: "NARCAN Nasal Spray 4 mg",
						item_brand: "Narcan",
						affiliation: "Narcan Shop",
						item_variant: "",
						item_category: ""
					}
				],
				value: EvaluateExpression(`(${props.odrQuantity} * ${ODR_KIT_PRICE}) + (${props.narcanQuantity} * ${NARCAN_PRICE})`),
				coupon: '',
				currency: 'USD',
			},
		});
	}, []);

	/*
	* send a code to the backend to check if its valid
	* first step which may be the end of it, or second step may be required if it's employee discount
	*/
	const handleSubmitDiscountCode = async () => {
		let response: any = {};
		
		const params = {
			discountCode: discountCodeInputted
		};

		try {
			response = await Api.post(ORDER.VALIDATE_DISCOUNT_CODE, params);
		}
		catch(e) {
			console.error('error validating code')
			props.setSnackbarMessage({ message: 'error validating code', type: 'error' });
		}

		if (response?.data?.error) {
			props.setSnackbarMessage({ message: response.data.error, type: 'error' });
		}
		else if (response?.data?.isCodeValid) {
			//apply the discount
			props.setSnackbarMessage({ message: 'Discount applied', type: 'success' });
			//setDiscountedUnitPrice(response.data.discountedUnitPrice);
				setDiscountedUnitPriceODRBox(response.data.discountedUnitPrice['ODR_KIT']);
				setDiscountedUnitPriceNarcan(response.data.discountedUnitPrice['NARCAN']);
				setconfirmedDiscountCode(params.discountCode);

				//recalculate taxes after discount applied, if address already entered
				if (inputs.shippingCity && inputs.shippingState && inputs.shippingAddress && inputs.shippingZip) {
					validateAddress();
				}
				
		}
		else {
			console.error('error validating code')
			props.setSnackbarMessage({ message: 'error validating code', type: 'error' });
		}
	}

	const routeChange = (path: string) => {
		history.push(path);
	};

	const handleCloseModal = () => {
		setOpenAddressModal(false);
	};

	const handleSetInputs = (key: string, value: any) => {
		setInputs({ ...inputs, [key]: value });
		setFormErrors({ ...formErrors, [key]: null });
		setSubmitError('');
	};

	const handleStateInputs = (value: any) => {
		setInputs({ ...inputs, shippingState: value, billingState: value });
		setFormErrors({ ...formErrors, shippingState: null, billingState: null });
		setSubmitError('');
	};

	const formatString = (str: string) => {
		if (!str) return '';
		let formatted = str[0].toUpperCase() + str.slice(1);
		return formatted.replace(/([A-Z])/g, ' $1').trim();
	};

	const onChecked = () => {
		const checkFlag = !checked;
		setChecked(checkFlag);
		if (checkFlag) {
			setInputs({ ...inputs, billingState: inputs.shippingState });
		} else {
			setInputs({ ...inputs, billingState: '', billingZip: '' });
		}
		setFormErrors({ ...formErrors, billingAddress: null, billingCity: null, billingState: null, billingZip: null });

	};

	const getTotalOrderDiscountAmount = () => {
		if (!confirmedDiscountCode) {
			return 0;
		}
		
		return RoundNumber(getODRDiscountAmount() + getNarcanDiscountAmount(), 2);
		//return Math.round(rawDiscountTotal*100)/100;
	}

	const getODRDiscountAmount = () => {
		if (!confirmedDiscountCode) {
			return 0;
		}

		let odrBoxDiscount = 0;

		if (discountedUnitPriceODRBox) {
			odrBoxDiscount = (ODR_KIT_PRICE - discountedUnitPriceODRBox) * props.odrQuantity;
		}

		return RoundNumber(odrBoxDiscount, 2);
		//return Math.round(odrBoxDiscount*100)/100; //todo use MathJS rmm

	}

	const getNarcanDiscountAmount = () => {
		if (!confirmedDiscountCode) {
			return 0;
		}

		let narcanDiscount = 0;

		if (discountedUnitPriceNarcan) {
			narcanDiscount = (NARCAN_PRICE - discountedUnitPriceNarcan) * props.narcanQuantity;
		}

		return RoundNumber(narcanDiscount, 2);
		//return Math.round(narcanDiscount*100)/100; //todo use MathJS rmm

	}

	const validateAddress = () => {
		//Add Shipping Info
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'add_shipping_info',
			ecommerce: {
				tax: EvaluateExpression(calculateTotalTax().toString(), 2),
				items: [
					{
						index: 0,
						price: ODR_KIT_PRICE,
						coupon: '',
						item_id: ODR_KIT,
						discount: 0,
						quantity: props.odrQuantity,
						item_name: "ODRescue Box",
						item_brand: "ODRescue",
						affiliation: "FFF Enterprises",
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
					{
						index: 0,
						price: NARCAN_PRICE,
						coupon: "",
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: props.narcanQuantity,
						item_name: "NARCAN Nasal Spray 4 mg",
						item_brand: "Narcan",
						affiliation: "Narcan Shop",
						item_variant: "",
						item_category: ""
					}
				],
				value: EvaluateExpression(`(${props.odrQuantity} * ${ODR_KIT_PRICE}) + (${props.narcanQuantity} * ${NARCAN_PRICE})`),
				coupon: '',
				currency: 'USD',
			},
		});

		const taxPayload: any = {
				toCity: inputs.shippingCity,
				toState: inputs.shippingState,
				toStreet: inputs.shippingAddress,
				toZip: inputs.shippingZip,
			};

		if (confirmedDiscountCode) {
			taxPayload.discount = getTotalOrderDiscountAmount() //unused?????
		}

		props.validateAddress({
			callback: setOpenAddressModal,
			taxPayload: taxPayload
		});
	};

	const calculateTax = (
		street: string = inputs.shippingAddress,
		city: string = inputs.shippingCity,
		state: string = inputs.shippingState,
		zip: string = inputs.shippingZip,
	) => {
		setInputs({ ...inputs, shippingAddress: street, shippingCity: city, shippingState: state, shippingZip: zip });
		props.getTaxRate({
			callback: () => {},
			taxPayload: {
				toCity: city,
				toState: state,
				toStreet: street,
				toZip: zip,
				amount: calculateUndiscountedOrderTotal(), //discount is applied sperately to get correct tax
				lineItems: [
					{
						quantity: props.odrQuantity,
						id: ODR_KIT,
						discount: getODRDiscountAmount()
					},
					{
						quantity: props.narcanQuantity,
						id: NARCAN_PRODUCT_ID,
						discount: getNarcanDiscountAmount()
					},
				]
			}
		});
	};

	const getDiscountCodeResultText = () => {
		if (discountCodeError) {
			return discountCodeError;
		}
		else if (confirmedDiscountCode) {
			return confirmedDiscountCodeMessage;
		}
		else {
			return ''
		}
	}

	const getDiscountCodeResultTextColor = () => {
		if (discountCodeError) {
			return 'red';
		}
		else {
			return 'green'
		}
	}

	const getODRKitPrice = () => {
		return discountedUnitPriceODRBox || ODR_KIT_PRICE;			
	}

	const getNarcanPrice = () => {
		return discountedUnitPriceNarcan || NARCAN_PRICE;
	}

	const onOrderCreated = (order: any) => {
		//Purchase
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'purchase',
			ecommerce: {
				tax: EvaluateExpression(calculateTotalTax().toString(), 2),
				items: [
					{
						index: 0,
						price: getODRKitPrice(),
						coupon: '',
						item_id: ODR_KIT,
						discount: 0,
						quantity: props.odrQuantity,
						item_name: "ODRescue Box",
						item_brand: "ODRescue",
						affiliation: "FFF Enterprises",
						item_variant: '',
						item_category: '',
					},
					{
						index: 0,
						price: getNarcanPrice(),
						coupon: "",
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: props.narcanQuantity,
						item_name: "NARCAN Nasal Spray 4 mg",
						item_brand: "Narcan",
						affiliation: "Narcan Shop",
						item_variant: "",
						item_category: ""
					}
				],
				value: EvaluateExpression(`(${props.odrQuantity} * ${getODRKitPrice()}) + (${props.narcanQuantity} * ${getNarcanPrice()})`),
				coupon: '',
				currency: 'USD',
				shipping: 0,
				transaction_id: order.orderNumber,
			},
		});
		props.clearPaymentInputs();
		routeChange('/order');
	};

	const onPlaceOrder = async () => {
		const errors: any = {};
		for (let [key, value] of Object.entries(inputs)) {
			if (requiredInputs().includes(key) && value === '') {
				errors[key] = `${formatString(key)} is required`;
			}
		}

		if (!emailRegex.test(inputs.email.toLowerCase())) errors.email = 'Invalid email address';

		if (!phoneRegex.test(inputs.phoneNumber)) errors.phoneNumber = 'Invalid phone number';

		if (!zipRegex.test(inputs.shippingZip)) errors.shippingZip = 'Invalid zip code';

		if (!checked && !zipRegex.test(inputs.billingZip)) errors.billingZip = 'Invalid zip code';

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		if (!props.taxRateLoaded) {
			props.setSnackbarMessage({ message: 'Tax rate not loaded', type: 'error' });
			return;
		}

		if (!ODR_KIT_PRICE) {
			props.setSnackbarMessage({ message: 'Product not loaded, please refresh page', type: 'error' });
			return;
		}

		// Order line for ODRescue Box
		const orderLines: any[] = [];

		const orderObj: any = {
				productId: ODR_KIT,
				shipAddress1: inputs.shippingAddress,
				shipAddress2: inputs.shippingAddress2,
				shipCity: inputs.shippingCity,
				shipState: inputs.shippingState,
				shipZip: inputs.shippingZip,
				billingAddress1: checked ? inputs.shippingAddress : inputs.billingAddress,
				billingAddress2: checked ? inputs.shippingAddress2 : inputs.billingAddress2,
				billingCity: checked ? inputs.shippingCity : inputs.billingCity,
				billingState: checked ? inputs.shippingState : inputs.billingState,
				billingZip: checked ? inputs.shippingZip : inputs.billingZip,
				shipName: inputs.name,
				billingName: inputs.name,
				businessName: inputs.businessName,
				email: inputs.email,
				phone: inputs.phoneNumber,
				quantity: props.odrQuantity,
				shipFromWarehouse: getProductTaxDetails(ODR_KIT)?.shipFromWarehouse?.warehouse || '',
				transactionToken: undefined,
			}

		if (confirmedDiscountCode) {
				orderObj.discountCode = confirmedDiscountCode;
			}

			orderLines.push(orderObj);

		// Add order line for narcan if selected
		//discount code will be copied if applicable; both use the same discount code
		if(props.narcanQuantity > 0) orderLines.push({
				...orderLines[0],
				productId: NARCAN_PRODUCT_ID,
				quantity: props.narcanQuantity,
				shipFromWarehouse: getProductTaxDetails(NARCAN_PRODUCT_ID)?.shipFromWarehouse?.warehouse || '',
		})

		//Add Payment Info
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'add_payment_info',
			ecommerce: {
				tax: EvaluateExpression(calculateTotalTax().toString(), 2),
				items: [
					{
						index: 0,
						price: getODRKitPrice(),
						coupon: '',
						item_id: ODR_KIT,
						discount: 0,
						quantity: props.odrQuantity,
						item_name: "ODRescue Box",
						item_brand: "ODRescue",
						affiliation: "FFF Enterprises",
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
					{
						index: 0,
						price: getNarcanPrice(),
						coupon: "",
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: props.narcanQuantity,
						item_name: "NARCAN Nasal Spray 4 mg",
						item_brand: "Narcan",
						affiliation: "Narcan Shop",
						item_variant: "",
						item_category: ""
					}
				],
				value: EvaluateExpression(`(${props.odrQuantity} * ${getODRKitPrice()}) + (${props.narcanQuantity} * ${getNarcanPrice()})`),
				coupon: '',
				currency: 'USD',
			},
		});

		

		// if (config.isAutomatedProcess) {
			try {
				//1) get the auth token from elevon which allows us to open the Lightbox payment pop-up
				const ccToken = await Api.post(
					`${API_BASE_URL.API_GATEWAY}/odr/ccPaymentToken`,
					{
						amount: calculateOrderTotal(),
						ssl_avs_address: checked ? inputs.shippingAddress : inputs.billingAddress,
						ssl_avs_zip: checked ? inputs.shippingZip : inputs.billingZip,
						ssl_vendor_id: 'SC963080',
					},
				);

				console.log(`got Elevon token: ${JSON.stringify(ccToken?.data)}`);

				if (ccToken?.data) {
					//2) open popup for user to input CC info for preauth
					openLightbox(ccToken.data);
				}
			} 
			catch (e) {
				console.error(`error getting Elevon token: ${JSON.stringify(e)}`);
			}
		// } 
		// else {
		// 	//Manual Process
		// 	//TODO: This process is broken. Should remove all logic related to this flag
		// 	// props.createOrder({ callback: () => routeChange('/order'), order: order });
		// }

		function openLightbox(token: any) {
			const paymentFields = {
				ssl_txn_auth_token: token,
				ssl_amount: calculateOrderTotal(),
				ssl_avs_address: checked ? inputs.shippingAddress : inputs.billingAddress,
				ssl_avs_zip: checked ? inputs.shippingZip : inputs.billingZip,
				ssl_vendor_id: 'SC963080',
			};

			const createOrderMethod = props.createOrder;

			const callback = {
				onError: function (error: any) {
					console.error('error!!' + JSON.stringify(error));
					props.setSnackbarMessage({ message: `Payment Error: ${JSON.stringify(error)}`, type: 'error' });
					props.setIsCaptchaValid(false)
				},
				onCancelled: function () {
					console.log('cancelled!!');
					props.setIsCaptchaValid(false);

					//for testng, you can just close the CC popup without enterng anything and it will simulate an order
					if (config.skipCCPayment) {
						createOrderMethod({ callback: onOrderCreated, order: orderLines });
						console.log('skipping CC for testing and creating order:', orderLines);

						//this validates and creates an order on the backend without an order no, for testng purposes
						return;
					}
				},
				onDeclined: function (response: any) {
					console.log('declined!!' + JSON.stringify(response));
					props.setSnackbarMessage({
						message: `Payment Declined: ${response.ssl_result_message}`,
						type: 'error',
						snackbarAutohide: 30000,
					});
					props.setIsCaptchaValid(false)
				},
				onApproval: function (response: any) {
					console.log('APPROVAL!!' + JSON.stringify(response));
					console.log('with tx uid!!' + response.ssl_txn_id);

					//"transactionToken" is a misnomer, as the txn_id is what Elevon wants
					//to convert from authonly to completed tx later
					orderLines.forEach((orderLine: any) => orderLine.transactionToken = response.ssl_txn_id)

					//3) once user's CC preauth is approved by elevon and we store the ssl_txn_id
					// (mis-named as transactionToken), create the order on our backend
					// this can result in "bad" orders being created in the DB with no order number when an SAP error occurs
					// To the user, it appears they placed an order successfully, but it won't ship unless manually fixed
					createOrderMethod({ callback: onOrderCreated, order: orderLines });
				},
			};

			const w = window as any;
			w.PayWithConverge.open(paymentFields, callback);

			return false;
		}
	};

	const getPlaceOrderTooltip = () => {
		const addressValidationText = 'shipping address must be validated'
		const recaptchaText = 'reCaptcha must be completed'
		const termsCheckText = 'you must agree to our terms of use'
		let tooltipTexts = []
		if(!props.taxRateLoaded) tooltipTexts.push(addressValidationText)
		if(!props.isCaptchaValid) tooltipTexts.push(recaptchaText)
		if(!termsChecked) tooltipTexts.push(termsCheckText)
		if(tooltipTexts.length > 0) {
			tooltipTexts[0] = tooltipTexts[0].charAt(0).toUpperCase() + tooltipTexts[0].slice(1)
			tooltipTexts.forEach((text: string, index: number) => {
				if(index === 0) return
				let newText = ', '
				if(index === tooltipTexts.length - 1) newText += 'and '
				tooltipTexts[index] = newText + text
			})
			tooltipTexts.push(' before placing order.')
		}
		return tooltipTexts.join('')
	}

	const calculateOrderTotal = () => {
		const odrTotal = EvaluateExpression(`${props.odrQuantity} * ${getODRKitPrice()}`)
		const narcanTotal = EvaluateExpression(`${props.narcanQuantity} * ${getNarcanPrice()}`)
		const taxes = calculateTotalTax()
		return EvaluateExpression(`${odrTotal} + ${narcanTotal} + ${taxes}`, 2)
	}

	const calculateUndiscountedOrderTotal = () => {
		const odrTotal = EvaluateExpression(`${props.odrQuantity} * ${ODR_KIT_PRICE}`)
		const narcanTotal = EvaluateExpression(`${props.narcanQuantity} * ${NARCAN_PRICE}`)
		const taxes = calculateTotalTax()
		return EvaluateExpression(`${odrTotal} + ${narcanTotal} + ${taxes}`, 2)
	}

	const calculateTotalTax = (): number => {
		if(props.taxRateLoaded && props.taxRate) {
			const taxes = props.taxRate.map((rate: any) => rate.tax.amount_to_collect)
			return EvaluateExpression(taxes.join(' + '))
		}
		return 0
	}

	const getProductTaxDetails = (productId: string) => {
		if(props.taxRateLoaded && props.taxRate) {
			const productTaxes = props.taxRate.find((rate: any) => rate.productId = productId)
			return productTaxes
		}
		return {}
	}

	const shippingAddressCard = () => {
		return (
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 3' }}>
					<h3>Shipping Address</h3>
				</Box>
				<TextField
					label="Address Line 1"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: 'span 3' }}
					value={inputs.shippingAddress}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('shippingAddress', event.target.value);
					}}
					error={formErrors.shippingAddress ? true : false}
					helperText={formErrors.shippingAddress ? formErrors.shippingAddress : ''}
					required
				/>
				<TextField
					label="Address Line 2"
					variant="outlined"
					sx={{ gridRow: '3', gridColumn: 'span 3' }}
					value={inputs.shippingAddress2}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('shippingAddress2', event.target.value);
					}}
				/>
				<TextField
					label="City"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '1' }}
					value={inputs.shippingCity}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('shippingCity', event.target.value);
					}}
					error={formErrors.shippingCity ? true : false}
					helperText={formErrors.shippingCity ? formErrors.shippingCity : ''}
					required
				/>
				<Autocomplete
					disablePortal
					options={dataList.StateCodes}
					getOptionLabel={(option: any) => `${option.value}(${option.label})`}
					value={
						inputs.shippingState ? dataList.StateCodes.find((code: any) => code.label === inputs.shippingState) : null
					}
					onChange={(event, newValue) => {
						checked
							? handleStateInputs(newValue?.label || '')
							: handleSetInputs('shippingState', newValue?.label || '');
					}}
					renderInput={params => (
						<TextField
							{...params}
							variant="outlined"
							label="State"
							error={formErrors.shippingState ? true : false}
							helperText={formErrors.shippingState || ''}
							required
						/>
					)}
				/>
				<TextField
					label="Zip Code"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '3' }}
					value={inputs.shippingZip}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('shippingZip', event.target.value);
					}}
					error={formErrors.shippingZip ? true : false}
					helperText={formErrors.shippingZip ? formErrors.shippingZip : ''}
					required
					InputProps={{
						inputComponent: ZipCodeInput as any,
					}}
				/>
				<Tooltip title={!enableSubmit && 'Please finish filling out Shipping Address before validating.'} arrow>
					<Box sx={{ gridRow: '5', gridColumn: '3', display: 'flex', justifyContent: 'flex-end' }} >
						<Button variant='contained' size='large'
							onClick={validateAddress} 
							disabled={!enableSubmit || props.taxRateLoaded}  
							startIcon={props.taxRateLoaded && <CheckCircleOutlineIcon sx={{color: 'white'}}/>}
							sx={{
								"&.Mui-disabled": {
									background: props.taxRateLoaded ? "#4caf50" : "#616161"
								}
							}}
						>{props.taxRateLoaded ? 'Validated' : 'Validate Address'}</Button>
					</Box>
				</Tooltip>
			</Paper>

		);
	};

	const billingAddressCard = () => {
		return (
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: '1', display: 'inherit', alignItems: 'center' }}>
					<h3>Billing Address</h3>
				</Box>
				<FormControlLabel
					sx={{ gridRow: '1', gridColumn: 'span 2' }}
					control={
						<Checkbox inputProps={{ 'aria-label': 'controlled' }} size="small" checked={checked} onChange={onChecked} />
					}
					label="Same as Shipping Address"
				/>
				<TextField
					label="Address Line 1"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: 'span 3' }}
					value={checked ? inputs.shippingAddress : inputs.billingAddress}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingAddress', event.target.value);
					}}
					error={formErrors.billingAddress ? true : false}
					helperText={formErrors.billingAddress ? formErrors.billingAddress : ''}
					disabled={checked}
					required
				/>
				<TextField
					label="Address Line 2"
					variant="outlined"
					sx={{ gridRow: '3', gridColumn: 'span 3' }}
					value={checked ? inputs.shippingAddress2 : inputs.billingAddress2}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingAddress2', event.target.value);
					}}
					disabled={checked}
				/>
				<TextField
					label="City"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '1' }}
					value={checked ? inputs.shippingCity : inputs.billingCity}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingCity', event.target.value);
					}}
					error={formErrors.billingCity ? true : false}
					helperText={formErrors.billingCity ? formErrors.billingCity : ''}
					disabled={checked}
					required
				/>
				<Autocomplete
					disablePortal
					options={dataList.StateCodes}
					getOptionLabel={(option: any) => `${option.value}(${option.label})`}
					value={
						inputs.billingState ? dataList.StateCodes.find((code: any) => code.label === inputs.billingState) : null
					}
					onChange={(event, newValue) => {
						handleSetInputs('billingState', newValue?.label || '');
					}}
					disabled={checked}
					renderInput={params => (
						<TextField
							{...params}
							variant="outlined"
							label="State"
							error={formErrors.billingState ? true : false}
							helperText={formErrors.billingState || ''}
							required
						/>
					)}
				/>
				<TextField
					label="Zip Code"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '3' }}
					value={checked ? inputs.shippingZip : inputs.billingZip}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingZip', event.target.value);
					}}
					error={formErrors.billingZip ? true : false}
					helperText={formErrors.billingZip ? formErrors.billingZip : ''}
					disabled={checked}
					required
					InputProps={{
						inputComponent: ZipCodeInput as any,
					}}
				/>
			</Paper>
		);
	};

	const customerInfoCard = () => {
		return (
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 3' }}>
					<h3>Contact Information</h3>
				</Box>
				<TextField
					label="Full Name"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: 'span 3' }}
					value={inputs.name}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('name', event.target.value);
					}}
					error={formErrors.name ? true : false}
					helperText={formErrors.name ? formErrors.name : ''}
					required
				/>
				<TextField
					label="Business Name"
					variant="outlined"
					sx={{ gridRow: '3', gridColumn: 'span 3' }}
					value={inputs.businessName}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('businessName', event.target.value);
					}}
				/>
				<TextField
					label="Email"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: 'span 3' }}
					value={inputs.email}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('email', event.target.value);
					}}
					error={formErrors.email ? true : false}
					helperText={formErrors.email ? formErrors.email : ''}
					required
				/>
				<TextField
					label="Phone Number"
					variant="outlined"
					sx={{ gridRow: '5', gridColumn: 'span 3' }}
					value={inputs.phoneNumber}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('phoneNumber', event.target.value);
					}}
					error={formErrors.phoneNumber ? true : false}
					helperText={formErrors.phoneNumber ? formErrors.phoneNumber : ''}
					required
					InputProps={{
						inputComponent: PhoneNumberInput as any,
					}}
				/>
			</Paper>
		);
	};

	const orderDetailsCard = () => {
		return (
			<Box className={classes.paymentDetails}>
				<h3>Order Details</h3>
				<Box>
					<ul>
						<li>
							<p className={'paymentDetailsLabel'}>
							ODRescue&trade; Box - Qty {props.odrQuantity} x ${getODRKitPrice()}:
							</p>
							<p className={'paymentDetailsData'}>
								${FormatCurrency( EvaluateExpression(`${props.odrQuantity} * ${getODRKitPrice()}`, 2) )}
							</p>
						</li>
						{props.narcanQuantity > 0 && <li>
							<p className={'paymentDetailsLabel'}>
							NARCAN&reg; Nasal Spray - Qty {props.narcanQuantity} x ${getNarcanPrice()}:
							</p>
							<p className={'paymentDetailsData'}>
								${FormatCurrency( EvaluateExpression(`${props.narcanQuantity} * ${getNarcanPrice()}`, 2) )}
							</p>
						</li>}


									{Boolean(getTotalOrderDiscountAmount() && getTotalOrderDiscountAmount() > 0) && 
									<>
										<li>
											<p className={'paymentDetailsLabel'}>Amount saved:</p>
											<p className={'paymentDetailsData'}>
												<b style={{ color: '#E40572' }}>
													${FormatCurrency(getTotalOrderDiscountAmount())}
												</b>
											</p>
										</li>
										{/*<li>
											<p className={'paymentDetailsLabel'}>
												Price after discount:
											</p>
											<p className={'paymentDetailsData'}>
												<b style={{ color: '#E40572' }}>
													${FormatCurrency(
														(props.odrQuantity * getODRKitPrice())
														+
														(props.narcanQuantity * getNarcanPrice())
													)}
												</b>
											</p>
										</li>*/}
									</>
							}


						<li>
							<p className={'paymentDetailsLabel'}>
								Estimated Tax:
							</p>
							<p className={'paymentDetailsData'}>
							{props.isTaxLoading ? (
								<CircularProgress style={{ color: globalColors.NARCAN_GRAY }} size={15} />
							) : (
								props.taxRateLoaded ? `$${FormatCurrency( EvaluateExpression(`${calculateTotalTax()}`, 2) )}` : 'TBD'
							)}
							</p>
						</li>
						<li>
							<p className={'paymentDetailsLabel'}>
								Shipping:
							</p>
							<p className={'paymentDetailsData'}>
								<b style={{color: globalColors.ODR_ORANGE}}>FREE</b>
							</p>
						</li>
						<li>
							<p className={'paymentDetailsLabel'}>
								Total:
							</p>
							<p className={'paymentDetailsData'}>
								<b>${FormatCurrency( calculateOrderTotal() )}</b>
							</p>
						</li>
					</ul>

				</Box>
				<ReactRecaptchaConnected />
				<FormControlLabel
					label={(<span>By checking this box, you are agreeing to our <a href={`${config.wordpressUrl}/terms-of-use`}>Terms of Use</a></span>)}
					className={classes.termsCheck}
					control={
						<Checkbox
							checked={termsChecked}
							onChange={(event, isChecked) => {setTermsChecked(isChecked)}}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					}
				/>
				<Tooltip
					title={getPlaceOrderTooltip()}
					arrow
				>
					<span>
						<Button onClick={onPlaceOrder} disabled={!props.taxRateLoaded || !props.isCaptchaValid || !termsChecked} variant="contained" size='large'>
							Place Order
						</Button>
					</span>
				</Tooltip>
			</Box>
		);
	};

	return (
		<Grid container item spacing={2} xs={12} md={8}>
			<Grid item xs={12}>
				<h1>Checkout</h1>
			</Grid>
			<Grid item xs={12} md={6}>
				{customerInfoCard()}
			</Grid>
			<Grid item xs={12} md={6}>
				{shippingAddressCard()}
			</Grid>
			<Grid item xs={12} md={6}>
				{billingAddressCard()}
			</Grid>
			<Grid item xs={12} md={6}>
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1'}}>
					<h3>Discount Code</h3>
					<div>Enter a discount code if you have one.</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: 16
					}}
					>
						<TextField
							label="Discount code"
							variant="outlined"
							sx={{ gridRow: '2', gridColumn: 'span 3' }}
							value={discountCodeInputted}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setDiscountCodeInputted(event.target.value);
							}}
						/>

						<Button 
							onClick={handleSubmitDiscountCode} 
							disabled={Boolean(!(discountCodeInputted?.length) || confirmedDiscountCode)} 
							startIcon={confirmedDiscountCode && <CheckCircleOutlineIcon sx={{color: 'white'}}/>}
							sx={{
								"&.Mui-disabled": {
									background: confirmedDiscountCode ? "#4caf50" : "#616161"
								}
							}}
							style={{
								marginLeft: 16
							}}>
								{confirmedDiscountCode ? 'Code accepted!' : 'Validate Code'}
						</Button>
					</div>

					<div style={{
						color: getDiscountCodeResultTextColor()
					}}>{getDiscountCodeResultText()}</div>
				</Box>
			</Paper>
				
			</Grid>
			<Grid xs={12} style={{
				marginTop: 18
			}}>
				{orderDetailsCard()}
			
		
			</Grid>
			<Modal open={openAddressModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
				<ValidateAddressModal closeModal={handleCloseModal} onSelect={calculateTax} />
			</Modal>
		</Grid>
	);
};