import { create, all } from 'mathjs'
const math = create(all,  {number: 'BigNumber', precision: 20});

export const EvaluateExpression = (expression: string, roundDecimals?: number) => {
    const value = math.evaluate(expression)
    const roundedValue = roundDecimals ? math.round(value, roundDecimals) : value
    return Number(roundedValue)
};

export const RoundNumber = (num: number, roundDecimals: number) => {
    return math.round(num, roundDecimals);
};
